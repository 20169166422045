<template>
  <banner-top />
  <NavigationBar />
  <router-view :key="$route.fullPath" />
  <home-footer />
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import BannerTop from "./components/BannerTop.vue";
import HomeFooter from "./components/MainFooter.vue";
export default {
  name: "App",
  components: {
    NavigationBar,
    BannerTop,
    HomeFooter,
  },
};
</script>

<style>
@import "@/assets/main.css";
</style>
