<template>
  <div class="max-w-7xl mx-auto">
    <div class="flex flex-col sm:flex-row">
      <OfficePages class="w-full sm:w-3/4" />
      <OfficeMenu class="w-full sm:w-1/4 mb-4" />
    </div>
  </div>
</template>

<script>
import OfficeMenu from "../components/Offices/OfficeMenu";
import OfficePages from "../components/Offices/OfficePages";
export default {
  name: "OfficesViewId",
  components: {
    OfficeMenu,
    OfficePages,
  },
};
</script>
