<template>
  <div class="lista-noticias m-2 p-2.5">
    <div v-if="pagina">
      <h3 class="text-xl font-bold text-regular-blue p-2.5">
        {{ pagina.titulo }}
      </h3>
      <div v-html="pagina.texto"></div>
    </div>
    <div
      class="mb-4 text-regular-blue"
      v-for="(u, i) in utilidades"
      :key="u.id"
    >
      <p v-if="u.titulo">
        <strong>{{ u.titulo }}</strong>
      </p>
      <p v-if="u.endereco">{{ u.endereco }}</p>
      <p v-if="u.telefone">{{ u.telefone }}</p>
      <hr v-if="i + 1 < utilidades.length" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    pagina: {},
    utilidades: [],
  }),
  created() {
    console.log(this.$route.params);
    if (this.$route.params.type === "pagina") {
      this.getPage();
    } else if (this.$route.params.type === "util") {
      this.getUtil();
    }
  },
  methods: {
    async getPage() {
      console.log(this.$route.params);
      try {
        this.loading = true;
        const response = await this.$axios.get(
          `/v1/paginas/${this.$route.params.id_page}`
        );
        this.pagina = response.data;
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        this.loading = false;
      }
    },
    async getUtil() {
      console.log(this.$route.params);
      try {
        this.loading = true;
        const response = await this.$axios.get(
          `/v1/utilidades?tipo_id=${this.$route.params.id_page}`
        );
        this.utilidades = response.data;
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
