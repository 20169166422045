<template>
  <div
    class="lista-noticias m-2 p-2.5 border-solid border border-gray rounded-lg box-border"
  >
    <h3 class="text-xl font-bold text-regular-blue p-2.5">{{ title }}</h3>

    <ul class="w-full p-2.5" v-if="Object.keys(noticiasList).length > 0">
      <li
        v-for="(noticia, index) in noticiasList.data"
        :key="index"
        :class="{ 'bg-gray-200': index % 2 === 0, 'bg-white': index % 2 !== 0 }"
        class="p-2.5"
      >
        <span>{{ formatDate(noticia.data, "DD/MM/YYYY") }}</span>
        -
        <a
          class="text-regular-blue text-justify"
          :href="getPage(noticia.id, slugify(noticia.titulo))"
        >
          {{ noticia.titulo }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import myMixin from "../../mixins";
export default {
  props: {
    title: {
      type: String,
      default: "Notícias",
    },
    noticiasList: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mixins: [myMixin],

  methods: {
    getPage(id, title) {
      return `${`/noticia/${id}/${title}`}`;
    },
  },
};
</script>

<style></style>
