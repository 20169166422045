<template>
  <div class="all-news my-4 mx-auto p-2.5 w-900-or-60">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <ul
      class="w-full mb-2.5 border border-solid border-gray-300 rounded"
      v-if="officesMenu?.menu?.length > 0"
    >
      <router-link
        v-for="(office, index) in officesMenu.menu"
        :key="office.id"
        :to="buildOfficeUrl(office)"
      >
        <li
          :class="[
            { 'border-t border-gray-300': index > 0 },
            { 'bg-blue-100': buildOfficeUrl(office) === $route.fullPath },
          ]"
          class="p-2.5"
        >
          <div class="text-regular-blue text-justify">
            {{ office.titulo || officesMenu.titulo }}
          </div>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";

export default {
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      default: "Secretarias",
    },
  },
  data() {
    return {
      loading: false,
      officesMenu: [],
    };
  },
  created() {
    console.log(this.$route);
    this.getMenu();
  },
  methods: {
    async getMenu() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          `/v1/secretarias/menu?sec=${this.$route.params.id}`
        );
        this.officesMenu = response.data;
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        this.loading = false;
      }
    },
    slugify(text) {
      return text
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "");
    },
    buildOfficeUrl(office) {
      return office.titulo
        ? `/secretarias/${this.officesMenu.id}/${this.slugify(
            this.officesMenu.titulo
          )}/${office.tipo}/${office.id}/${this.slugify(office.titulo)}`
        : `/secretarias/${this.officesMenu.id}/${this.slugify(
            this.officesMenu.titulo
          )}`;
    },
  },
};
</script>

<!-- Estilo com Tailwind CSS -->
<style lang="scss"></style>
