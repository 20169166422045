<template>
  <div class="card-noticias m-4 p-2.5">
    <div
      class="grid grid-cols-2 gap-4 content-start md:grid-cols-3"
      v-if="Object.keys(cardsList).length > 0"
    >
      <a
        v-for="item in cardsList"
        :key="item.id"
        class="p-4 bg-regular-gray border-solid border border-gray rounded-lg flex flex-col justify-center items-center text-center"
        :href="item.link"
        :target="item.externo ? '_blank' : '_self'"
      >
        <img alt="" class="w-9 m-3" :src="item.icone" />
        <span class="text-regular-blue">
          {{ item.titulo }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          name: "Diário Oficial de Anastácio",
          icon: require("@/assets/icons/jornal.svg"),
        },
        {
          name: "Portal da Transparência",
          icon: require("@/assets/icons/lupa.svg"),
        },
        {
          name: "Webmail Oficial Anastácio",
          icon: require("@/assets/icons/mail.svg"),
        },
        {
          name: "Portal de Convênios",
          icon: require("@/assets/icons/hands.svg"),
        },
        {
          name: "Serviços Online",
          icon: require("@/assets/icons/cloud.svg"),
        },
        {
          name: "Recursos Humanos",
          icon: require("@/assets/icons/people.svg"),
        },
        {
          name: "Nota Eletrônica",
          icon: require("@/assets/icons/notes.svg"),
        },
        {
          name: "VTN 2022 ITR",
          icon: require("@/assets/icons/sheets.svg"),
        },
        {
          name: "Código Tributário Municipal",
          icon: require("@/assets/icons/folder.svg"),
        },
      ],
      cardsList: [],
    };
  },
  methods: {
    async getCardsList() {
      const res = await this.$axios.get(`/servicos`).catch((e) => {
        console.error("Serviços", e);
      });
      if (res.status === 200) {
        this.cardsList = res.data;
      }
    },
  },
  mounted() {
    this.getCardsList();
  },
};
</script>

<style></style>
