<template>
  <div class="home-footer bg-regular-gray">
    <img class="w-full h-full -mb-2" src="@/assets/bg-footer.png" alt="" />
    <div class="home-footer-container">
      <footer
        class="p-7 text-center flex flex-col items-center md:items-end md:flex-row md:justify-between"
      >
        <div class="p-3">
          <a href="/"
            ><img
              class="h-12 md:h-18"
              src="@/assets/logos/logoPrefAnastacioBlue.png"
              alt=""
          /></a>
          <div class="text-left text-xs text-regular-blue pl-0 pt-2 md:pl-16">
            <p>R. João Leite Ribeiro, 754 - Centro</p>
            <p>67 3245-3540</p>
            <p>ouvidoria@anastacio.ms.gov.br</p>
          </div>
        </div>
        <div class="icon-medias flex flex-row justify-center">
          <a href="https://www.facebook.com/PrefsAnastacio/" target="_blank"
            ><img class="h-6 w-6 pl-1" src="@/assets/icons/facebook.svg" alt=""
          /></a>
          <a href="https://www.instagram.com/PrefsAnastacio/" target="_blank"
            ><img
              class="h-6 w-6 pl-1"
              src="@/assets/icons/instagram.svg"
              alt=""
          /></a>
          <a
            href="https://www.youtube.com/channel/UCk-OhJTZ6qFaCdvIdpfQ1Iw"
            target="_blank"
            ><img class="h-6 w-6 pl-1" src="@/assets/icons/youtube.svg" alt=""
          /></a>
        </div>
        <div
          class="flex flex-row justify-end max-w-screen-sm w-full align-end md:w-auto"
        >
          <a href="https://sistemaweb.com.br/" target="_blank">
            <img class="h-6 pl-1" src="@/assets/logos/dev.png" alt="" />
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.home-footer {
  &-container {
    max-width: 1440px;
    margin: 0 auto;
  }

  .icon-medias {
    img {
      filter: brightness(0) saturate(100%) invert(27%) sepia(35%)
        saturate(3509%) hue-rotate(187deg) brightness(95%) contrast(92%);
    }
  }
}
</style>
