import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.css";
import VueSocialSharing from "vue-social-sharing";

// Add the solid icons to the library
library.add(fas, far);

const app = createApp(App);

app.use(router);
app.use(VueAwesomePaginate);
app.use(VueSocialSharing);

let baseURL = null;
if (window.location.hostname === "localhost") {
  baseURL = process.env.VUE_APP_ROOT_API_DEV;
} else if (window.location.hostname === "portal.anastacio.ms.gov.br") {
  baseURL = process.env.VUE_APP_ROOT_API_PROD;
} else {
  baseURL = process.env.VUE_APP_ROOT_API_DEV;
}

const $axios = axios.create({
  baseURL: baseURL,
});

app.config.globalProperties.$axios = { ...$axios };

// app.component("font-awesome-icon", FontAwesomeIcon).mount("#app");
app.mount("#app");
