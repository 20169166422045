<template>
  <div class="gallery-view flex flex-col sm:flex-row">
    <AllGalleriesShow class="w-full sm:w-3/4 p-5" />
    <offices-list class="w-full sm:w-1/4 mb-4" />
  </div>
</template>

<script>
import AllGalleriesShow from "../components/Gallery/AllGalleriesShow.vue";
import OfficesList from "@/components/Offices/OfficesList.vue";
export default {
  components: {
    AllGalleriesShow,
    OfficesList,
  },
};
</script>

<style lang="scss">
.gallery-view {
  max-width: 1440px;
  margin: 0 auto;
}
/* .noticia {
  max-width: 960px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
  padding: 2rem 1rem;
} */
</style>
