<template>
  <div class="news-views flex flex-col lg:flex-row">
    <AllNewsShow title="Todas as Notícias" />
    <offices-list />
  </div>
</template>

<script>
import AllNewsShow from "@/components/News/AllNewsShow.vue";
import OfficesList from "@/components/Offices/OfficesList.vue";
export default {
  components: {
    AllNewsShow,
    OfficesList,
  },
};
</script>

<style lang="scss">
.all-news-views {
  max-width: 1440px;
  margin: 0 auto;
}
.noticia {
  max-width: 960px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
  padding: 2rem 1rem;
}
</style>
