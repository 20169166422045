import moment from "moment";
import "moment/locale/pt-br";

export default {
  data() {
    return {
      lastSize: 0,
      lastValue: "",
    };
  },
  methods: {
    formatDate: function (value, format = "DD [de] MMMM [de] YYYY") {
      return moment(value).format(format);
    },
    formatHour: function (date, hour) {
      return moment(new Date(`${date} ${hour}`)).format("HH[H]mm");
    },
    slugify(text) {
      return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
    },
    accessibleFontSize(value) {
      if (this.lastValue === value && this.lastSize !== 0) {
        return;
      }
      this.lastValue = value;
      if (value === "add" && this.lastSize === 0) {
        this.lastSize = 1;
        document.body.classList.add("larger-font");
        document.body.classList.remove("smaller-font");
      } else if (value === "rm" && this.lastSize === 0) {
        this.lastSize = 2;
        document.body.classList.add("smaller-font");
        document.body.classList.remove("larger-font");
      } else {
        this.lastSize = 0;
        document.body.classList.remove("smaller-font");
        document.body.classList.remove("larger-font");
      }
    },
  },
};
