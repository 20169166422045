<template>
  <div class="banner">
    <div class="icon-list">
      <div class="icon-list-icons">
        <a href="http://sic.anastacio.ms.gov.br/" target="_blank"
          ><img src="../assets/icons/esic.svg" alt=""
        /></a>
        <a href="https://transparencia.anastacio.ms.gov.br/" target="_blank"
          ><img src="../assets/icons/transparencia.svg" alt=""
        /></a>
        <a href="https://www.gov.br/transferegov/pt-br" target="_blank"
          ><img src="../assets/icons/sincov.svg" alt=""
        /></a>
        <a href="https://www.facebook.com/PrefsAnastacio/" target="_blank"
          ><img src="../assets/icons/facebook.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/PrefsAnastacio/" target="_blank"
          ><img src="../assets/icons/instagram.svg" alt=""
        /></a>
        <a
          href="https://www.youtube.com/channel/UCk-OhJTZ6qFaCdvIdpfQ1Iw"
          target="_blank"
          ><img src="../assets/icons/youtube.svg" alt=""
        /></a>
      </div>
    </div>

    <div class="banner-top">
      <a href="/">
        <div class="flex">
          <div>
            <img src="../assets/logos/brasao.png" alt="" />
          </div>
          <div class="flex flex-col justify-center ml-4 leading-none">
            <div class="uppercase font-bold regular-blue inter ml-2 sm:ml-3">
              Prefeitura Municipal
            </div>
            <div class="font-black text-4xl sm:text-5xl regular-blue inter">
              Anastácio
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.banner {
  box-shadow: 0 10px 4px -4px #d5d5d5;
  // background: url(./../assets/bg_topo.png);

  display: flex;
  flex-direction: column;
  align-items: center;
  &-top {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: start;

    @media screen and (max-width: 1280px) {
      & > a {
        img {
          height: 81px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
      & > a {
        img {
          height: 50px;
        }
      }
    }
  }

  .icon-list {
    height: 40px;
    background-color: #0a65ab;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-right: 1rem;
    &-icons {
      width: 100%;
      max-width: 1440px;
      display: flex;
      justify-content: right;
      & > a {
        margin: 0 0.5rem;
        align-items: center;
        justify-content: right;
        display: flex;
        & > img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

.regular-blue {
  color: #0965ab;
}
</style>
