<template>
  <div class="all-news m-4 p-2.5 w-900-or-60">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <h3 v-if="!loading" class="text-xl font-bold text-regular-blue p-2.5">
      Secretarias
    </h3>
    <ul
      class="w-full mb-2.5 border-solid border-2 rounded"
      v-if="Object.keys(officesList).length > 0"
    >
      <li v-for="(office, index) in officesList" :key="index" class="p-2.5">
        <a
          class="text-regular-blue text-justify"
          :href="`/secretarias/${office.id}/${this.slugify(office.titulo)}`"
        >
          {{ office.titulo }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";
export default {
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      default: "Notícias",
    },
  },
  mixins: [myMixin],
  data() {
    return {
      loading: false,
      officesList: [],
      currentPage: 1,
    };
  },
  methods: {
    async getOffices() {
      this.loading = true;
      const res = await this.$axios.get(`v1/secretarias`).catch((e) => {
        console.error("Offices", e);
      });
      if (res.status === 200) {
        this.officesList = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getOffices();
  },
};
</script>

<style lang="scss"></style>
