<template>
  <div
    class="lista-noticias m-2 p-2.5 border-solid border border-gray rounded-lg box-border"
  >
    <h3 class="bg-gray-200 text-xl font-bold text-regular-blue p-2.5">
      Endereço
    </h3>

    <ul class="p-2.5" v-if="Object.keys(officeData).length > 0">
      <li>
        Endereço: <span>{{ officeData.endereco }}</span>
      </li>
      <li>
        email: <span>{{ officeData.email }}</span>
      </li>
      <li>
        Horário de Funcionamento: <span>{{ officeData.horario }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import myMixin from "../../mixins";
export default {
  props: {
    title: {
      type: String,
      default: "Notícias",
    },
    officeData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mixins: [myMixin],

  methods: {},
};
</script>

<style></style>
