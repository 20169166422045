<template>
  <div class="all-news m-2 p-2.5 w-900-or-60">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <h3 v-if="!loading" class="text-xl font-bold text-regular-blue p-2.5">
      {{ officeData.descricao }}
    </h3>
    <div class="p-2.5">
      <div class="desc" v-html="officeData.descricao1"></div>
    </div>
    <OfficeNews :noticiasList="officeNews" />
    <OfficeAddress :officeData="officeData" />
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import OfficeNews from "../Offices/OfficeNews.vue";
import OfficeAddress from "./OfficeAddress.vue";
export default {
  components: {
    Loading,
    OfficeNews,
    OfficeAddress,
  },
  props: {},
  data() {
    return {
      loading: false,
      officeData: [],
      officeNews: [],
    };
  },
  methods: {
    async getOfficeData(id) {
      this.loading = true;
      const res = await this.$axios.get(`/v1/secretarias/${id}`).catch((e) => {
        console.error("Secretarias", e);
      });
      if (res.status === 200) {
        this.officeData = res.data;
      }
      this.loading = false;
    },
    async getOfficeNews(id) {
      const res = await this.$axios.get(`/noticias?sec=${id}`).catch((e) => {
        console.error("Secretarias", e);
      });
      if (res.status === 200) {
        this.officeNews = res.data;
      }
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push("/secretarias/1");
    }
    this.getOfficeData(this.$route.params.id);
    this.getOfficeNews(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.desc {
  display: flow-root;
  img {
    padding: 10px;
  }
}
</style>
