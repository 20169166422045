<template>
  <div class="all-news m-4 p-2.5">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <h3 v-if="!loading" class="text-xl font-bold text-regular-blue p-2.5">
      {{ title }}
    </h3>
    <ul
      class="w-full mb-2.5 border-solid border-2 rounded"
      v-if="Object.keys(galeriasList).length > 0"
    >
      <li
        v-for="(galeria, index) in galeriasList.data"
        :key="index"
        :class="{ 'bg-gray-200': index % 2 === 0, 'bg-white': index % 2 !== 0 }"
        class="p-2.5 flex flex-col md:flex-row"
      >
        <div>
          <a
            class="inline-block w-60"
            :href="getPage(galeria.id, slugify(galeria.titulo))"
          >
            <img
              class="w-auto h-auto max-w-full max-h-full"
              :src="galeria.fotos"
              alt=""
            />
          </a>
        </div>
        <div class="p-2.5">
          <p>
            <a
              :href="getSec(galeria.sec_id, slugify(galeria.secretaria_name))"
              >{{ galeria.secretaria_name }}</a
            >
          </p>
          <p>
            <a :href="getPage(galeria.id, slugify(galeria.titulo))">{{
              galeria.titulo
            }}</a>
          </p>
          <p class="pl-2">
            {{ galeria.descricao }}
          </p>
          <p>
            <span>{{ formatDate(galeria.data, "DD/MM/YYYY") }}</span>
          </p>
          <p>
            <small>{{ galeria.local }}</small>
          </p>
        </div>
      </li>
    </ul>

    <div
      class="pagination"
      v-if="
        Object.keys(galeriasList).length > 0 && galeriasList.data.length > 0
      "
    >
      <vue-awesome-paginate
        :total-items="galeriasList.total"
        :items-per-page="parseInt(galeriasList.per_page)"
        :max-pages-shown="5"
        :show-breakpoint-buttons="false"
        :show-jump-buttons="false"
        v-model="galeriasList.current_page"
        :on-click="handleChangePage"
      >
        <template #prev-button>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="black"
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>
          </span>
        </template>

        <template #next-button>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="black"
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";
export default {
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      default: "Galerias",
    },
  },
  mixins: [myMixin],
  data() {
    return {
      loading: false,
      galeriasList: [],
      currentPage: 1,
    };
  },
  methods: {
    async getGalerias(pg = 1) {
      this.loading = true;
      const res = await this.$axios
        .get(`/v1/galerias/all?&page=${pg}`)
        .catch((e) => {
          console.error("Galerias", e);
        });
      if (res.status === 200) {
        this.galeriasList = res.data;
        this.currentPage = res.data.current_page;
      }
      this.loading = false;
    },

    handleChangePage(pg) {
      if (this.currentPage !== pg) {
        this.$router.push(`/galerias/${pg}`);
        // this.getNoticias(pg);
      }
    },
    getSec(id, title) {
      return `${`/secretarias/${id}/${title}`}`;
    },

    getPage(id, title) {
      return `${`/galeria/${id}/${title}`}`;
    },
  },
  mounted() {
    if (!this.$route.params.pg) {
      this.$router.push("/galerias/1");
    }
    this.getGalerias(this.$route.params.pg);
  },
};
</script>

<style lang="scss">
.pagination .paginate-buttons {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #e6e6e6;
  border: none;
}

.pagination .back-button,
.pagination .next-button {
  margin-inline: 10px;
  border-radius: 25px;
}

.pagination .first-button {
  border-start-start-radius: 25px;
  border-end-start-radius: 25px;
}
.pagination .last-button {
  border-start-end-radius: 25px;
  border-end-end-radius: 25px;
}

.pagination .back-button svg {
  transform: rotate(180deg) translateY(-2px);
}
.pagination .next-button svg {
  transform: translateY(2px);
}

/* select second element of .paginate-buttons */
.pagination li:nth-child(2) > .paginate-buttons.number-buttons {
  border-start-start-radius: 25px;
  border-end-start-radius: 25px;
  transition: none;
}

/* select one element before last of .paginate-buttons */
.pagination li:nth-last-child(2) > .paginate-buttons.number-buttons {
  border-start-end-radius: 25px;
  border-end-end-radius: 25px;
}

.pagination .active-page {
  background-color: #2980b9;
  color: #fff;
}

.pagination .active-page {
  background-color: #2980b9;
  color: #fff;
}

.pagination .paginate-buttons:hover {
  background-color: #f5f5f5;
}

.pagination .active-page:hover {
  background-color: #388ac1;
}
.pagination .back-button:active,
.pagination .next-button:active {
  background-color: #e6e6e6;
}
</style>
